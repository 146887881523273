import { FC, ReactNode, memo } from "react";

import { WithDataAttr } from "apps/website/types";

import Text from "../Text";
import { Align, AvailableTag, Color } from "../Text.map";

import { bodySizeMap, TBodySize } from "./TextBody.map";

export interface IBodyProps extends WithDataAttr {
  id?: string;
  tag?: AvailableTag;
  size?: TBodySize
  align?: Align;
  color?: Color;
  className?: string;
  children: ReactNode;
}

const TextBody: FC<IBodyProps> = ({ id, size = 1, align, color, tag = "p", "data-testid": testId, className, children }) => (
  <Text
    id={ id }
    size={ bodySizeMap[size] }
    align={ align }
    tag={ tag }
    color={ color }
    data-testid={ testId }
    className={ className }
  >
    { children }
  </Text>
);

export default memo(TextBody);
